.cx-next {
    border-top: 1px solid #ccc;

}

.link-project span:hover {
    color: #444484;
    transition: .3s;
    cursor: pointer;
}

.cx-hero .img {
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}

.cx-hero {
    padding-left: 6.6% !important;
    padding-right: 6.6% !important;
    padding-bottom: 9.6% !important;
}

.cx-next.ar .cx-prev {
    padding-left: 0px;
    padding-right: 54px;
    border-right: 1px solid #ccc;
    border-left: 0px;
}

.cx-prev {
    padding-top: 10%;
    padding-left: 54px;
    padding-bottom: 10%;
    border-left: 1px solid #ccc;
}

.cx-next.ar .prev-cx {
    padding-right: 0px;
    padding-left: 54px;
}

.prev-cx {
    padding-top: 10%;
    padding-right: 54px;
    padding-bottom: 10%;
}

.cx-next.ar .cx-prev img,
.cx-next.ar .prev-cx img {
    margin-right: 0px;
    margin-left: 54px;
}

.cx-prev img,
.prev-cx img {
    width: 40%;
    margin-right: 54px;
    height: 171px;
}

.cx-next.ar .cx-prev .text {
    margin-right: 0px !important;
    margin-left: 54px !important;
}

.cx-prev .text {
    margin-right: 54px;
}

.cx-next.ar .cx-prev .text h4,
.cx-next.ar .prev-cx .text h4,
.cx-next.ar .cx-prev .text p,
.cx-next.ar .prev-cx .text p {
    font-family: "Rubik";
}

.cx-prev .text h4,
.prev-cx .text h4 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 20.106px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
}

.cx-next.ar .img-next {
    margin-right: 0px !important;
    margin-left: 54px !important;
}

.img-next {
    width: 45%;
    height: 263px;
    margin-right: 54px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.cx-next.ar .img-prev {
    margin-left: 0px !important;
    margin-right: 54px !important;
}

.img-prev {
    margin-left: 54px;
}

.cx-prev .text p,
.prev-cx .text p {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 20.106px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.cx-next.ar .prev-cx img {
    margin-left: 0px !important;
    margin-right: 44px !important;
}

.prev-cx img {
    margin-left: 44px !important;
}

.cx-next.ar .prev-cx svg {
    margin-right: 0px;
    margin-left: 34px;
    transform: rotateY(180deg);
}

.prev-cx svg {
    margin-right: 34px;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.cx-next.ar .cx-prev svg {
    transform: rotateY(180deg);
}

.cx-prev svg {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.cx-slider.rrr .next {
    right: 10px;
}

.cx-slider.rrr .prev {
    left: 10px;
}


@media all and (min-width:1540px) {

    .cx-next.ar .cx-prev img,
    .cx-next.ar .prev-cx img {
        margin-right: 0px;
        margin-left: 54px;
    }

    .cx-prev img,
    .prev-cx img {
        width: 40%;
        margin-right: 54px;
        height: 263px;
    }


    .cx-hero {
        padding-left: 24.6% !important;
        padding-right: 24.6% !important;
    }

    .cx-slider.rrr .next {
        right: 20%;
    }

    .cx-slider.rrr .prev {
        left: 20%;
    }

    .cx-slider .next {
        right: -33px;
        top: 40%;
    }

    .cx-slider .prev {
        left: -33px;
        top: 39.5%;
    }

    .cx-slider.rrr {
        padding-left: 24.5% !important;
        padding-right: 24.5% !important;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .cx-next.ar .cx-prev,
    .cx-next.ar .prev-cx {
        padding: 16px 18px !important;
    }

    .cx-prev,
    .prev-cx {
        padding: 16px 18px;
        display: flex;
        justify-content: space-around;
    }

    .img-next {
        height: 100%;
        width: 50%;
    }

    .cx-next.ar .cx-prev .text h4 {
        margin-right: 0px;
        margin-left: 30px;
    }

    .cx-prev .text h4 {
        margin-right: 30px;
    }

    .cx-prev .text h4,
    .prev-cx .text h4 {
        font-size: 25.255px;
    }

    .cx-prev .text p,
    .prev-cx .text p {
        font-size: 18px;
    }

    .cx-next.ar .cx-prev .text {
        margin-right: 0px;
        margin-left: 6px;
    }

    .cx-prev .text {
        margin-right: 6px;
    }

    .cx-next.ar .prev-cx .img-prev {
        margin-left: 0px !important;
        margin-right: 22px !important;
    }

    .prev-cx .img-prev {
        margin-left: 22px !important;
    }
}


@media all and (max-width:767px) {

    .cx-next.ar .cx-prev,
    .cx-next.ar .prev-cx {
        padding: 16px 18px !important;
    }

    .cx-prev,
    .prev-cx {
        display: flex;
        padding: 16px 18px;
        border-left: 1px solid #ccc;
        justify-content: space-around;
    }

    .cx-next.ar .cx-prev .img-next {
        margin-right: 0px;
        margin-left: 24px;
    }

    .cx-prev .img-next {
        width: 54px;
        height: 54px;
        margin-right: 24px;
    }

    .cx-next.ar .prev-cx .img-next {
        margin-inline: 0px !important;
    }

    .prev-cx .img-next {
        width: 54px;
        height: 54px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .cx-next.ar .prev-cx svg {
        margin-right: 0px;
        margin-left: 10px;
    }

    .prev-cx svg {
        margin-right: 10px;
    }

    .cx-prev svg,
    .prev-cx svg {
        width: 23.551px;
        height: 16.775px;
    }

    .cx-next.ar .cx-prev .text {
        margin-right: 0px;
        margin-left: 10px;
    }

    .cx-prev .text {
        margin-top: 4px;
        margin-right: 10px;
    }

    .cx-next.ar .prev-cx .text {
        margin-right: 0px;
        margin-left: 10px;
    }

    .prev-cx .text {
        margin-right: 10px
    }

    .cx-prev .text h4,
    .prev-cx .text h4 {
        font-size: 7.975px;
        margin-bottom: 5.7px;
    }

    .cx-prev .text p,
    .prev-cx .text p {
        font-size: 5.982px;
        margin-bottom: 0px;
    }

    .cx-next.ar .cx-prev .text {
        margin-left: 0px;
    }

    .cx-prev .text {
        margin-right: 0px;
    }
}