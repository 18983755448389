.categories {
    padding-left: 6.6%;
}

.Categories-card img {
    width: 80%;

}

.img-brands-imges {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 60%;
    height: 207px;
    margin-bottom: 32px;
}

.categories .container-fluid {
    padding-right: 6.6%;
}

.categories.ar .Categories-card p {
    font-family: "Rubik";
}

.Categories-card p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.categories .col {
    margin-bottom: 126px;
}

@media all and (min-width:1901px) {
    .img-brands-imges {
        width: 90%;
        height: 301px;
    }

    .categories {
        padding-left: 31.1%;
    }

    .categories .container-fluid {
        padding-right: 32.54% !important;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .img-brands-imges {
        width: 90%;
        height: 301px;
    }

    .categories {
        padding-left: 24.54%;
    }

    .categories .container-fluid {
        padding-right: 24.54% !important;
    }
}

@media all and (max-width:767px) {
    .categories {
        padding: 40px;
        padding-top: 0px;
        padding-right: 0px;
    }

    .categories .col-md-4 {
        margin-bottom: 16px;
    }

    .Categories-card p {
        font-size: 15.862px;
    }

    .Categories-card img {
        width: 100%;
        margin-bottom: 16px;
    }
}