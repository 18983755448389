.message-main-con {
    padding-top: 126px;
    padding-bottom: 126px;
    background: #E9E9E9;
}

.message-main-con.ar .container-fluid {
    padding-right: 6.6%;
    padding-left: 0px;
}

.message-main-con .container-fluid {
    padding-left: 6.6%;
}

.message-main-con.ar .text {
    padding-left: 0px;
    padding-right: 20px;
}

.message-main-con .text {
    padding-left: 20px;
}

.message-main-con.ar h5,
.message-main-con.ar h4,
.message-main-con.ar p,
.message-main-con.ar h6,
.message-main-con.ar h6 {
    font-family: "Rubik";
}

.message-main-con h5 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    /* 52.5% */
    margin-bottom: 32px;
}

.message-main-con h4 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 47.727% */
    margin-bottom: 63px;
}

.message-main-con p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    /* 116.667% */
    margin-bottom: 20px;
}

.message-main-con h6 {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
    /* 87.5% */
}

.message-main-con h6 span {
    font-weight: bold;
    display: inline-block;
    margin-top: 31px;
}

.message-main-con.ar .img {
    padding-left: 0px;
    padding-right: 118px;
}

.message-main-con .img {
    padding-left: 118px;
}

.message-main-con .img img {
    width: 272px;
    /* height: 292px; */
}

@media all and (min-width:1901px) {
    .message-main-con.ar .container-fluid {
        padding-left: 12%;
        padding-right: 30%;
    }

    .message-main-con .container-fluid {
        padding-left: 30%;
    }

    .message-main-con p {

        width: 70%;
    }

    .message-main-con.ar .img {
        padding-right: 40px;
        padding-left: 0px;
    }

    .message-main-con .img {
        padding-left: 40px;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .message-main-con.ar .container-fluid {
        padding-left: 0px;
        padding-right: 24%;
    }

    .message-main-con .container-fluid {
        padding-left: 25%;
    }

    .message-main-con p {

        width: 70%;
    }

    .message-main-con.ar .img {
        padding-right: 40px;
        padding-left: 0px;
    }

    .message-main-con .img {
        padding-left: 40px;
    }
}

@media all and (width:1024px) {
    .message-main-con {
        padding-top: 53px;
        padding-bottom: 53px;
    }

    .message-main-con.ar .img {
        padding-left: 0px;
        padding-right: 20px;
    }

    .message-main-con .img {
        width: 100%;
        height: 50%;
        padding-left: 20px;
    }

    .message-main-con .img img {
        width: 68%;
        /* height: 100%; */
    }

    .message-main-con h5 {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 32px;
    }

    .message-main-con h4 {
        font-size: 26px;
        margin-bottom: 32px;
    }

    .message-main-con p {
        font-size: 12px;
        margin-bottom: 32px;
    }

    .message-main-con h6 {
        font-size: 18px;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .message-main-con {
        padding-top: 53px;
        padding-bottom: 53px;
    }

    .message-main-con.ar .img {
        padding-left: 0px;
        padding-right: 20px;
    }

    .message-main-con .img {
        width: 100%;
        height: 30%;
        padding-left: 20px;
    }

    .message-main-con .img img {
        width: 68%;
        /* height: 100%; */
    }

    .message-main-con h5 {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 32px;
    }

    .message-main-con h4 {
        font-size: 26px;
        margin-bottom: 32px;
    }

    .message-main-con p {
        font-size: 12px;
        margin-bottom: 32px;
    }

    .message-main-con h6 {
        font-size: 18px;
    }
}

@media all and (max-width:767px) {
    .message-main-con.ar .container-fluid {
        padding-right: 3.6%;
    }

    .message-main-con {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .message-main-con h5 {
        line-height: 1.2;
        font-size: 15.429px;
        margin-bottom: 7.5px;
    }

    .message-main-con h4 {
        font-size: 16.372px;
        margin-bottom: 15.5px;
    }

    .message-main-con p {
        font-size: 10.243px;
        margin-bottom: 7.7px;
    }

    .message-main-con h6 {
        font-size: 10.243px;
        margin-bottom: 40px;
    }

    .message-main-con.ar .img {
        padding-right: 0px;
    }

    .message-main-con .img {
        width: 100%;
        height: 100%;
        padding-left: 0px;
    }

    .message-main-con .img img {
        width: 100%;
        /* height: 100%; */
    }
}