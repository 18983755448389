.cx-hero {
    padding-top: 126px;
    padding-bottom: 126px;
}

.cx-hero img {
    width: 100%;
}

.cx-hero.ar .text {
    padding-left: 0px;
    padding-right: 126px;
}

.cx-hero .text {
    padding-left: 126px;
}

.cx-hero.ar .text h2,
.cx-hero.ar .text p,
.cx-hero .text.ar h6 {
    font-family: "Rubik";
}

.cx-hero .text h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 32px;
}

.cx-hero .text p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 54px;
}

.cx-hero .text h6 {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .cx-hero {
        padding-top: 90px;
        padding-bottom: 90px;
        padding-left: 40px !important;
        padding-right: 40px !important;

    }

    .cx-hero .img {
        width: 100%;
        height: 100%;
    }

    .cx-hero.ar .text {
        padding-left: 0px;
        padding-right: 40px;
    }

    .cx-hero .text {
        padding-left: 40px;
    }

    .cx-hero .text h2 {
        font-size: 38px;
        margin-bottom: 20px;
    }

    .cx-hero .text p {
        font-size: 18px;
        margin-bottom: 40px;
    }

    .cx-hero .text h6 {
        font-size: 12px;
    }
}


@media all and (max-width:767px) {
    .row-m-0 {
        margin: 0;
    }

    .cx-hero {
        padding: 40px;

        &.ar {
            padding: 40px !important;
        }
    }

    .cx-hero .img {
        width: 79%;
        height: 100%;
        margin-bottom: 16px;
    }

    .cx-hero .col-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cx-hero .text {
        padding-left: 0px;
        padding-right: 40px;
    }

    .cx-hero .text h2 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .cx-hero .text p {
        font-size: 18px;
        margin-bottom: 16px;
    }

    .cx-hero .text h6 {
        font-size: 9px;
    }


}