hr {
    margin-top: 126px;
    margin-bottom: 126px;
}

.con-sm-case+hr:last-of-type {
    display: none;
}



.link-LFHO {
    margin-bottom: 126px;
}

.LFHO {
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.LFHO.ar .text {
    padding-left: unset;
    padding-right: 54px;
}

.LFHO .text {
    padding-left: 54px;
}

.LFHO.ar .text h2,
.LFHO.ar .text h3,
.LFHO.ar .text p,
.LFHO.ar .text .read-more-LFHO h6 {
    font-family: "Rubik";
}

.LFHO .text h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.LFHO .text h3 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 54px;
}

.LFHO .text p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 54px;
}

.LFHO .text .read-more-LFHO {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.LFHO .text .read-more-LFHO h6 {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-right: 16px;
    cursor: pointer;
}

.LFHO .text .read-more-LFHO h6:hover {
    color: #242447;
    transition: .3s;
}

.LFHO.ar .text .read-more-LFHO svg {
    transform: rotateY(180deg);
}

.LFHO .text .read-more-LFHO svg {
    width: 50px;
    height: 50px;
}

.LFHO .text-2 {
    padding-left: 0px;
    padding-right: 54px;
}

.LFHO.ar .text-2 {
    padding-left: 54px;
    padding-right: 0px;
}


.Advanced-Technology {
    margin-bottom: 126px;
}

.col-img-LFHO {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 512px;
}

@media all and (min-width:1901px) {
    .LFHO {
        padding-left: 31.6%;
        padding-right: 29.6%;

        &.ar {
            padding-left: 30.6%;
            padding-right: 30.6%;
        }
    }
}


@media all and (min-width:1540px) and (max-width:1900px) {
    .LFHO {
        padding-left: 25.5%;
        padding-right: 24.6%;

        &.ar {
            padding-left: 24.6%;
            padding-right: 25.5%;
        }
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .link-LFHO {
        margin-bottom: 90px;
    }

    .con-sm-case {
        padding-top: 0px !important;
        padding-left: 20px !important;
    }

    .LFHO .text h2 {
        font-size: 26px;
    }

    .LFHO .text h3 {
        font-size: 26px;
        margin-bottom: 32px;
    }

    .LFHO .text p {
        font-size: 12px;
        margin-bottom: 32px;
    }

    .LFHO .text .read-more-LFHO h6 {
        font-size: 26px;
    }

    hr {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .order-2 img {
        margin-left: 15px;
    }

    .col-img-LFHO {
        height: 304px;
    }

}

@media all and (max-width:767px) {
    .LFHO .text .read-more-LFHO svg {
        width: 25px;
        height: 25px;
    }

    .LFHO .text p {
        line-height: 20px;
    }

    .col-img-LFHO {
        height: 290px;
    }

    .Advanced-Technology {
        margin-bottom: 0px;
    }

    .link-LFHO {
        margin-bottom: 40px;
    }

    .con-sm-case {
        padding: 40px !important;
        padding-top: 0px !important;
    }

    .col-img-LFHO {
        padding: 0;
        margin-bottom: 16px;
    }

    .col-img-text {
        padding: 0;
    }

    .LFHO.ar .text {
        padding-right: 0px;
    }


    .LFHO .text,
    .LFHO.ar .text-2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .LFHO .text h2 {
        font-size: 16px;
        margin-bottom: 4px;
    }

    .LFHO .text h3 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .LFHO .text p {
        font-size: 10px;
        margin-bottom: 16px;
    }

    .LFHO .text .read-more-LFHO h6 {
        font-size: 15.16px;
        margin-right: 10px;
    }

    hr {
        margin-top: 0px;
        margin-bottom: 40px;
    }

    .LFHO .order-2 {
        order: 1 !important;
    }

    .LFHO .order-1 {
        order: 2 !important;
    }

    .order-2 img {
        margin-left: 0px;
    }

}