.text-arrow {
    cursor: pointer;
}

.case-study-sec.ar.h .text-arrow,
.featured-project.ar.h .text-arrow {
    direction: rtl !important;
}

.case-study-sec.ar.h .text-arrow .text,
.featured-project.ar.h .text-arrow .text {
    margin-right: 0px;
    margin-left: 88px;

}

.case-study-sec.ar.h .text-arrow .text+svg,
.featured-project.ar.h .text-arrow .text+svg {
    transform: rotateY(180deg);
}

@media all and (min-width:3025px) {

    .about-us {
        height: 37vh !important;
    }
}

.test-img {
    background-image: url("../../Assets/Images/1.png");
}

@media all and (min-width:768px) and (max-width:1024px) {

    .case-study-sec.ar.h .text-arrow .text,
    .featured-project.ar.h .text-arrow .text {
        margin-right: 0px;
        margin-left: 10px;
    }
}

@media all and (max-width:767px) {

    .case-study-sec.ar.h .case-text,
    .featured-project.ar.h .col-md-5 {
        justify-content: flex-start;
    }

    .case-study-sec.ar.h .text-arrow .text,
    .featured-project.ar.h .text-arrow .text {
        margin-right: 0px;
        margin-left: 38px;
    }

}