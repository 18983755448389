.case-study-sec {
    padding: 0;
    height: 70vh;
    font-family: Roboto Slab;
    color: var(--primery-color, #444484);
    padding-left: 0px;
    padding-right: 0px;

    &.ar {
        font-family: "Rubik";
    }
}

.case-study-sec .row {
    height: 100%;
}

.case-study-sec .row .col-md-7 {
    height: 100%;
}

.case-study-sec .text-arrow {
    display: flex;
    align-items: center;
}

.case-study-sec.ar .text-arrow {
    direction: ltr;

}

.case-study-sec .text {
    margin-right: 88px;
}

.case-study-sec .text-arrow h2 {
    font-size: 36px;
    font-weight: 400;
}

.case-study-sec .text-arrow p {
    font-size: 36px;
    font-weight: 300;
}

.case-img {
    height: 512px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.case-img .img-fluid {
    width: 100%;
}

@media all and (max-width: 767px) {

    .case-study-sec {
        padding-left: 28px !important;
        padding-right: 28px !important;
        margin-bottom: 32px;
        height: 100% !important;
    }

    .case-study-sec .row .col-md-7 {
        height: 200px;
    }

    .case-img {
        border: none;
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: flex-start;
    }

    .case-study-sec .text-arrow {
        margin-top: 16px;
    }



    .case-study-sec .text-arrow h2 {
        font-size: 19.053px;
    }

    .case-study-sec .text-arrow p {
        font-size: 14.29px;
    }

    .case-study-sec.ar .case-text {
        justify-content: flex-end;
    }

    .case-text {
        padding: 0;
        justify-content: flex-start;
    }

    .case-study-sec .text-arrow svg {
        width: 30px;
        height: 35px;
        margin-bottom: 20px;
    }

    .case-study-sec .text {
        margin-right: 34px;
    }


}

@media all and (min-width:2500px) {
    .case-study-sec {
        height: 45vh;
    }

    .case-study-sec .row .col-md-7 {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .case-study-sec {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-bottom: 0px !important;
        height: 30vh;
    }

    .case-img {
        height: 350px;
    }


    .case-img img {
        height: 356px;
        margin-bottom: 0px;
    }

    .case-study-sec.ar .case-text {
        padding-left: unset;
        padding-right: 10px;
    }

    .case-text {
        padding-left: 10px;
        justify-content: center;
    }

    .case-study-sec .text-arrow h2 {
        font-size: 22px;

    }

    .case-study-sec .text {
        margin-right: 10px;
    }

    .case-study-sec .text-arrow p {
        font-size: 22px;
    }

    .case-study-sec .text-arrow svg {
        width: 40px;
        height: 40px;
    }

    .case-study-sec .case-text {
        padding-left: 35px;
    }

}