.categories {
    padding-right: 6.6%;

}

.logos-con {
    /* padding-top: calc(6.6% + 50px); */
    padding-right: 6.6%;

}

.categories.ar .Categories-card {
    margin-right: 0px;
    margin-left: 54px;
}

.Categories-card {
    cursor: pointer;
    margin-right: 54px;

}



.link-project a {
    color: var(--nutral-color, #CCC);

}

.link-project a:hover {
    color: #444484;
    transition: .3s;
}

.logo-brands-img {
    /* transform: scale(.9); */
    height: 55px;
}

.col-logos {
    margin-bottom: 50px;
    padding: 0px;
}


.col-logos:not(:nth-child(4n)) {
    margin-right: 50px;

    &.ar {
        margin-right: 0px;
        margin-left: 50px;
    }
}

.categories.ar .nothing {
    font-family: "Rubik";
}

.nothing {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 354px;
}

.brands-con.ar .search-btn {
    font-family: "Rubik";
}

.categories.select-new {
    padding-left: 0px;
}

.categories.select-new.ar {
    padding-right: 0px;
}

.categories.select-new .col {
    margin-bottom: 0px;
}

.categories.select-new .container-fluid {
    padding-right: 0px !important;
}


.categories.select-new select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, #444484 50%),
        linear-gradient(135deg, #444484 50%, transparent 50%),
        linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 19px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
    font-size: 18px;
    color: #444484;
    font-family: Roboto;
    border-color: #CCC;
    font-weight: 300;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 93%;

    &:focus {
        border-color: #CCC;
        outline: none;
    }
}

.categories.select-new select option {
    font-weight: 300;
}



.categories.select-new.ar select {
    margin-right: 0px;
    background-position:
        calc(11% - 20px) calc(1em + 2px),
        calc(11% - 15px) calc(1em + 2px),
        100% 0;
}

@media all and (min-width:1901px) {
    .categories.ar {
        padding-right: 11.1%;
    }

    .img-brands-imges {
        width: 100% !important;
        height: 180px !important;
    }

    .bbb {
        padding-top: calc(4.5% + 50px) !important;
    }

    .logos-con.ar {
        padding-right: 30.2%;
        padding-top: 5.2%;
        padding-left: 27.54%;
    }

    .logos-con {
        padding-right: 28.2%;
        padding-top: 5.2%;
        padding-left: 31.54%;
    }

    .logo-brands-img {
        height: 45px;
    }

    .col-logos:not(:nth-child(4n)) {
        margin-right: 50px;
    }

    .brands-con.ar .text-con-brands {
        width: 96% !important;
    }

    .categories.select-new.ar .select-2 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .categories.select-new .select-2 {
        margin-left: 17px;
    }

    .categories.select-new .select-2 {
        width: 95%;
    }

    .categories.select-new.ar .Categories-card {
        margin-right: 0px;
        margin-left: 10px;
    }

    .categories.select-new .Categories-card {
        margin-right: 0px;
    }

    .categories.select-new.ar .search-btn {
        margin-left: 0px;
        margin-right: 45px;
    }

    .search-btn {
        margin-left: 45px;
    }
}


@media all and (min-width:1540px) and (max-width:1900px) {
    .img-brands-imges {
        width: 100% !important;
        height: 180px !important;
    }

    .bbb {
        padding-top: calc(4.5% + 50px) !important;
    }

    .logos-con {
        padding-right: 21.54%;
        padding-top: 5.2%;
        padding-left: 25.54%;
    }

    .logo-brands-img {
        height: 45px;
    }

    .col-logos:not(:nth-child(4n)) {
        margin-right: 50px;
    }

    .brands-con.ar .text-con-brands {
        width: 96% !important;
    }

    .categories.select-new.ar .select-2 {
        margin-right: 0px;
        margin-left: 0px;
    }

    .categories.select-new .select-2 {
        margin-left: 17px;
    }

    .categories.select-new .select-2 {
        width: 95%;
    }

    .categories.select-new.ar .Categories-card {
        margin-right: 0px;
        margin-left: 10px;
    }

    .categories.select-new .Categories-card {
        margin-right: 0px;
    }

    .categories.select-new.ar .search-btn {
        margin-left: 0px;
        margin-right: 45px;
        outline: none;
        background-color: transparent;
    }

    .search-btn {
        margin-left: 45px;
    }
}

@media all and (min-width:768px) and (max-width:1023px) {
    .logo-brands-img {
        height: 50px;
    }

    .col-logos:not(:nth-child(2n)) {
        margin-right: 80px;
    }

    .categories.ar .Categories-card {
        margin-left: 0px;
    }

    .categories.select-new.ar select {
        background-position:
            calc(24.1% - 20px) calc(1em + 2px),
            calc(24% - 15px) calc(1em + 2px),
            100% 0;
    }
}

@media all and (max-width:767px) {
    .nothing {
        font-size: 15px;
    }

    .logos-con {
        padding-top: 10%;
        padding-bottom: 0%;

        &.ar {
            padding: 40px !important;
        }
    }


    .logo-brands-img {
        height: 20px;
    }

    .bbb {
        padding-top: 4%;
    }

    .col-logos {
        margin-bottom: 15px;
    }

    .Categories-card {
        padding-right: 10px;
        margin-left: 0px !important;
        margin-right: 0px;
    }

    .row-sm-categories {
        margin: 0px;
    }

    .img-brands-imges {
        width: 100%;
    }

    .categories .col {
        margin-bottom: 40px;
    }
}

@media all and (max-width:767px) {
    .categories.select-new select {
        margin-right: 0px;
        font-size: 13px;
        padding: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 90%;
        margin-bottom: 16px;
    }

    .categories.select-new {
        padding-right: 0px;
    }

    .categories.select-new.ar .Categories-card {
        margin-right: 0px;
        padding-right: 0px;
    }

    .categories.select-new.ar .row-sm-categories .col {
        padding-right: 0px;
    }

}