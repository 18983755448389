.cx-sample-project {
    margin-top: 126px;
}

.link-sample-project {
    border: 0px;
}

.sample-hero {
    border-top: 0px;
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.cx-slider.cx-new-update {

    margin-top: 0px !important;
}

.sample-new-edit {
    border-bottom: unset;
}

.sample-hero .img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.link-project-con p a {
    color: #ccc;
}

.link-project-con p a:hover {
    color: #444484;
    transition: .3s;
}

.category-name {
    cursor: pointer;

    &:hover {
        color: #444484;
        transition: .3s;
    }
}

@media all and (max-width:767px) {
    .cx-sample-project {
        margin-top: 40px;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .cx-sample-project {
        margin-top: 90px;
    }

    .cx-slider.cx-new-update {
        padding-left: 6% !important;
        padding-right: 6% !important;
    }
}

@media all and (min-width:1901px) {
    .sample-hero.ar {
        padding-left: 31.1%;
        padding-right: 29.6%;
    }

    .sample-hero {
        padding-left: 27.6%;
        padding-right: 31.1%;
    }

    .sample-hero {
        padding-left: 31.1%;
        padding-right: 27.6%;
    }

    .sample-hero.ar .text {
        padding-right: 100px;
        padding-left: 0px;
    }

    .sample-hero .text {
        padding-right: 0px;
    }

    .cx-slider.cx-new-update {
        padding-left: 3.7% !important;
        padding-right: 3.7% !important;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .sample-hero {
        padding-left: 24.6%;
        padding-right: 24.6%;
    }

    .sample-hero.ar .text {
        padding-right: 100px;
        padding-left: 0px;
    }

    .sample-hero .text {
        padding-right: 0px;
    }

    .cx-slider.cx-new-update {
        padding-left: 3.7% !important;
        padding-right: 3.7% !important;
    }
}

@media all and (max-width:767px) {
    .cx-slider.cx-new-update {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
}