textarea {
    height: 300px;
}

.contact-map-con {
    width: 50%;
    height: 184px;
    border: 1px solid #ccc;
    padding: 8px;
    margin-bottom: 54px;

}

.contact-map-con iframe {
    height: 100%;
    width: 100%;
}


.contacts.ar {
    font-family: "Rubik";
}

.location-map {
    margin-bottom: 16px !important;
}

.Vacancies-text h6 {
    font-family: Roboto;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 300;
}

@media all and (min-width:1540px) {
    .contact-map-con {
        width: fit-content;
    }
}

@media all and (max-width:767px) {

    .contact-map-con {
        width: 84%;
    }

    .Vacancies-text h6 {
        font-size: 16px;
        margin-bottom: 16px;
    }
}