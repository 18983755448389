.vision {
    padding-top: 126px;
    padding-bottom: 126px;
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.mission {
    padding-left: 20px;
}

.mission h2,
.Values h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 47.727% */
    margin-bottom: 54px;
}

.mission p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 116.667% */
}

.mission p:first-of-type {
    margin-bottom: 126px;
}

.Values {
    padding-left: 126px;
    margin-bottom: 54px;
}

.Values p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.Values p:not(:last-child) {
    margin-bottom: 50px;
}

.Values p span {
    font-weight: 700;
}

@media all and (min-width:1540px) {
    .vision {
        padding-left: 24%;
        padding-right: 24%;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .mission h2,
    .Values h2 {
        font-size: 26px;
        margin-bottom: 32px;
    }

    .mission p:first-of-type {
        margin-bottom: 54px;
    }

    .mission p {
        font-size: 12px;
    }

    .Values {
        padding-left: 66px;
    }

    .Values p {
        font-size: 12px;
    }
}

@media all and (max-width:767px) {
    .vision {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .mission h2,
    .Values h2 {
        font-size: 22px;
        margin-bottom: 16px;
    }

    .mission p {
        font-size: 10px;
    }

    .mission p:last-child {
        margin-bottom: 32px;
    }

    .mission p:first-of-type {
        margin-bottom: 32px;
    }

    .Values {
        padding-left: 20px;
    }

    .Values p {
        font-size: 10px;
    }

    .Values p:not(:last-child) {
        margin-bottom: 32px;
    }
}