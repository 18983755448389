.career-con {
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.career-con.ar .Vacancies-text {
    padding-left: 0px;
    padding-right: 54px;
}

.Vacancies-text {
    padding-left: 54px;
    padding-top: 126px;
    padding-bottom: 126px;
    color: var(--primery-color, #444484);
}

.link-career {
    margin: 0;
}

.career-con.ar .Vacancies-form {
    border-right: 0px;
    border-left: 1px solid #ccc;
    padding-right: 0px;
    padding-left: 54px;
}

.Vacancies-form {
    border-right: 1px solid #ccc;
    color: var(--primery-color, #444484);
    padding-top: 126px;
    padding-bottom: 126px;
    padding-right: 54px;
}

.career-con.ar .Vacancies-form form label,
.career-con.ar .Vacancies-form form .flex-aswad-col span,
.career-con.ar .submit-btn button,
.career-con.ar .Vacancies-text h3,
.career-con.ar .Vacancies-form h3,
.career-con.ar .Vacancies-text p {
    font-family: "Rubik";
}

.Vacancies-form form label {
    font-family: Roboto;
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 300;
}

.Vacancies-form form input {
    height: 50px;
}

.Vacancies-form form .mb-3 {
    margin-bottom: 54px !important;
}

input[type="file"] {
    display: none;
}

.Vacancies-form form .flex-aswad-col {
    display: flex;
    flex-direction: column;
}

.Vacancies-form form .flex-aswad-col span {
    margin-top: 20px;
    border: 1px solid #444484;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.Vacancies-form form .flex-aswad-col span:hover {
    background-color: #444484;
    color: white;
    transition: .3s;
}

.submit-btn {
    display: flex;
    justify-content: flex-end;
}

.submit-btn button {
    border: none;
    background-color: #444484;
    color: white;
    font-family: Roboto;
    font-size: 18px;
    padding: 10px 20px;
}

.submit-btn button:hover {
    transition: .3s;
    border: 1px solid #444484;
    background-color: transparent;
    color: #444484;
}

.Vacancies-text h3,
.Vacancies-form h3 {
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 54px;
}

.Vacancies-text p {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 8px;
}

@media all and (max-width:767px) {
    .career-con.ar .Vacancies-form {
        border-right: 0px;
        border-left: 0px;
    }

    .career-con .o-1,
    .career-con .o-2 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .career-con.ar .Vacancies-text {
        padding-right: 25px;
        padding-left: 0px;
    }

    .Vacancies-text {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 0px;
        padding-left: 40px;
        border-bottom: 1px solid #ccc;
    }

    .Vacancies-text h3,
    .Vacancies-form h3 {
        font-size: 26px;
        margin-bottom: 16px;
    }

    .Vacancies-text p {
        font-size: 16px;
    }

    .career-con.ar .Vacancies-form {
        padding-inline: 25px !important;
    }

    .Vacancies-form {
        border-right: none;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 40px;
        padding-left: 40px;
    }

    .Vacancies-form form label {
        font-size: 14px;

    }

    .Vacancies-form form input {
        width: 85%;
    }

    .Vacancies-form form .flex-aswad-col a,
    .submit-btn button {
        padding: 5px;
        font-size: 14px;
    }
}

@media all and (min-width:1901px) {
    .career-con {
        padding-left: 31%;
        padding-right: 31%;

        &.ar {
            padding-left: 31%;
            padding-right: 31%;
        }
    }

    .career-con.ar .Vacancies-form {
        padding-right: 0px;
    }

    .Vacancies-form {
        padding-left: 0px;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .career-con {
        padding-left: 24.6%;

        &.ar {
            padding-left: 0px;
            padding-right: 24.6%;
        }
    }

    .career-con.ar .Vacancies-form {
        padding-right: 0px;
    }

    .Vacancies-form {
        padding-left: 0px;
    }
}