@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: "Rubik";
  font-weight: 300;
  src: url(./Assets/fonts/Rubic/Rubik-Light.ttf);
}

@font-face {
  font-family: "Rubik";
  font-weight: 400;
  src: url(./Assets/fonts/Rubic/Rubik-Regular.ttf);
}

@font-face {
  font-family: "Rubik";
  font-weight: 500;
  src: url(./Assets/fonts/Rubic/Rubik-Medium.ttf);
}

@font-face {
  font-family: "Rubik";
  font-weight: 600;
  src: url(./Assets/fonts/Rubic/Rubik-SemiBold.ttf);
}

@font-face {
  font-family: "Rubik";
  font-weight: 700;
  src: url(./Assets/fonts/Rubic/Rubik-Bold.ttf);
}

@font-face {
  font-family: "Rubik";
  font-weight: 800;
  src: url(./Assets/fonts/Rubic/Rubik-ExtraBold.ttf);
}

@font-face {
  font-family: "Rubik";
  font-weight: 900;
  src: url(./Assets/fonts/Rubic/Rubik-Black.ttf);
}

.link-project.ar,
.link.ar {
  font-family: "Rubik";
}

:root {
  --primery-color: #434485;
  --nutral-color: #CCC;

}

.btn-close {
  background: transparent url("./Assets/close.svg") center / 3.5em auto no-repeat !important;
  opacity: 1 !important;
  padding-bottom: 50px !important;
}


.modal-backdrop.show {
  background-color: #CCC !important;
  opacity: 0.9 !important;
}

.modal-header {
  border-bottom: 0px !important;
  background-color: transparent;

}

.modal-content {
  background-color: transparent;
  border: 0px !important;
}

.modal-body {
  background-color: white;
  border-bottom: 0px !important;
  margin: 0 !important;
  padding: 0 !important;

}

.modal.ar .modal-body .text {
  padding-left: 0px !important;
  padding-right: 20px !important;
}

.modal-body .text {
  padding-left: 20px !important;
  padding-top: 20px !important;
  color: #434485;

}

.modal-body .Subscribe {
  font-family: Roboto;
  font-size: 20.314px;
  font-weight: 300;
  margin-bottom: 20px;
}

.modal-body .Subscribe-input button {
  color: #434485 !important;
  border: 1px solid #434485;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.modal-body .Subscribe-input button {
  font-size: 11px;
}

.modal-body .Subscribe-input {
  margin-bottom: 22px;
}

.modal-body .Subscribe-input button:hover {
  cursor: pointer;
  transition: .3s;
  background-color: #434485;
  color: white !important;
}

.modal.ar .modal-body .Subscribe-input input {
  border-right: 1px solid #434485;
  border-left: 0px;
}

.modal-body .Subscribe-input input {
  border: 1px solid #434485;
  width: 100%;
  border-right: 0px;
  height: 28px;
}

.modal-body .Subscribe-input input:focus {
  /* border: none; */
  color: #434485;
  outline: none;
}

.modal-body .Subscribe-input input::placeholder {
  color: #CCC;
  font-size: 11.314px;
}

.modal-body .text h3 {
  font-size: 16px;
  font-weight: 300;
  font-family: Roboto;
  margin-bottom: 10px;
}

.modal-body svg {
  width: 100%;
  height: 100%;
}

.modal-header .btn-close {
  margin: 0px !important;
}

.flex-aswad-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  list-style: none;
}

body {
  overflow-x: hidden;
}

.img-fluid {
  height: 100%;
}

@media all and (min-width:1540px) {
  .modal-body .Subscribe-input input {
    height: 30px;
  }



  .modal-body .text h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  /* .modal-body .text h3 {
    font-size: 45px;
    margin-bottom: 40px;
  } */

  /* .modal-body .Subscribe-input input::placeholder {
    font-size: 25px !important;
  }

  .modal-body .Subscribe-input input {
    height: 55px;
    font-size: 25px !important;
  }

  .modal-body .Subscribe-input a {
    font-size: 25px;
  } */
}

@media all and (min-width:768px) and (max-width:1023px) {
  .modal.ar .modal-body .text {
    padding-left: 0px !important;
    padding-right: 40px !important;
  }

  .modal-body .text {
    padding-left: 40px !important;
    padding-top: 22px !important;
    padding-bottom: 22px;
    color: #434485;
  }

  .modal-body .text h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

@media all and (max-width:767px) {
  .modal-body .text {
    /* padding: 20px; */
    padding-top: 10px !important;
  }

  .modal-body .text h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .modal-body .Subscribe-input input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px !important;
    font-size: 12px;
  }

  .modal-body .Subscribe-input input::placeholder {
    font-size: 10px !important;
  }

  .modal-body .Subscribe-input button {
    height: 25px;
    font-size: 12px;
   
  }


}
