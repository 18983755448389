.hero {
    height: 60vh;
    font-family: Roboto Slab;
    color: var(--primery-color, #444484);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.ar {
        font-family: "Rubik";
    }
}

.hero h1 {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 10px;
}

.hero >div {
    font-size: 36px;
    font-weight: 300;
  
}


.hero svg {
    animation-name: down;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.hero .one{
    margin-top: 40px;
}
.hero .two svg {
    animation-delay: 0.3s;
}

.hero .three svg {
    animation-delay: 0.6s;
}






@keyframes down {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

@media all and (min-width:2500px) {
    .hero {
        height: 70vh;
    }

    .hero h1,
    .hero > div {
        font-size: 54px;

    }

}

@media all and (max-width: 767px) {
    .hero {
        padding-right: 71px;
        padding-left: 71px;
    }

    .hero h1 {
        font-size: 20px;
        text-align: center;
    }

    .sample-hero .img,
    .cx-hero .img {
        height: 227px !important;
    }

    .hero >div {
        font-size: 20px;
        font-weight: 200;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .hero {
        padding-top: 100px;
    }

    .sample-hero .img {
        height: 122%;
    }

    .hero h1 {
        text-align: center;
    }
}