.translation-con {
    position: fixed;
    bottom: 0;
    right: 20px;
    font-family: Roboto Slab;

    & .dropdown-menu {
        color: var(--primery-color, #444484);
        padding-left: 10px;

        & li {
            cursor: pointer;
        }

        & li:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    & .dropdown-menu span {
        margin-right: 10px;
    }

    & .btn-trans-drop-down span {
        margin-right: 20px;
    }
}

.li-trans {
    margin-top: 30px !important;
    font-family: Roboto Slab;
}

.li-trans.ar {
    font-family: "Rubik";
}

@media all and (min-width:1540px) {
    .li-trans {
        margin-top: 59px !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .li-trans {
        margin-top: 50px !important;
    }
}

@media all and (max-width:767px) {
    .li-trans {
        margin-top: 43px !important;
    }
}