.about-hero {
  padding-top: 126px;
  padding-bottom: 126px;
  padding-left: 6.6%;
  padding-right: 6.6%;
  color: var(--primery-color, #444484);
  font-family: Roboto Slab;

  &.ar {
    font-family: "Rubik";
  }
}

.about-hero .img {
  height: 554px;
  width: 100%;
  background-image: url("../../../Assets/Images/about.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.about-hero .img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.about-hero .img svg {
  width: 70px;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.about-hero .img:hover svg {
  transform: scale(1.2);
}

.about-hero video {
  display: none;
}
.about-hero .display .img {
  display: none;
}

.about-hero .display video {
  display: block;
}

.about-hero.ar .text {
  padding-left: 0px;
  padding-right: 102px;
}

.about-hero .text {
  padding-left: 102px;
}

.about-hero .text h2 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 126px;
}

.about-hero.ar .text p {
  font-family: "Rubik";
}

.about-hero .text p {
  font-family: Roboto;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}

@media all and (min-width: 1901px) {
  .about-hero {
    padding-left: 30.9%;
    padding-right: 28%;

    &.ar {
      padding-left: 30%;
      padding-right: 30.9%;
    }
  }
}

@media all and (min-width: 1540px) and (max-width: 1900x) {
  .about-hero {
    padding-left: 24.6%;
    padding-right: 24%;

    &.ar {
      padding-left: 24%;
      padding-right: 24.6%;
    }
  }
}

@media all and (max-width: 767px) {
  .about-hero {
    padding: 40px;

    &.ar {
      padding: 40px !important;
    }
  }

  .about-hero .img {
    height: 300px;
    margin-bottom: 40px;
  }

  .about-hero.ar .text {
    padding-right: 0px;
  }

  .about-hero .text {
    padding-left: 0px;
    margin-top: 17px;
  }

  .about-hero .text h2 {
    font-size: 22px;
    margin-bottom: 16px;
  }

  .about-hero .text p {
    font-size: 10px;
    line-height: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .about-hero {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  .about-hero .img {
    height: 68%;
  }

  .about-hero .text h2 {
    margin-bottom: 90px;
    font-size: 26px;
  }

  .about-hero .text p {
    font-size: 12px;
  }

  .about-hero.ar .text {
    padding-left: 0px;
    padding-right: 35px;
  }

  .about-hero .text {
    padding-left: 35px;
  }
}

@media (min-width: 1540px) {
  ._size {
    width: 42% !important;
  }
}

@media (min-width: 1901px) {
  ._size {
    width: 55% !important;
  }
}

video {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
}
