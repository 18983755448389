.blog-con {
    padding-top: 126px;
    padding-left: 6.6%;

    &.ar {
        padding-left: 0px;
        padding-right: 6.6%;
    }
}

.blog-con.ar .text h4,
.blog-con.ar .text ul,
.blog-con.ar .card-blog .date,
.blog-con.ar .card-blog h4,
.blog-con.ar .card-blog p,
.blog-con.ar .flex-aswad-between h5,
.blog-con.ar .card-blog h3 {
    font-family: "Rubik";
}


.blog-con .text h4 {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 32px;
}

.blog-con.ar .text {
    padding-left: 0px;
    padding-right: 54px;
    border-right: 1px solid #ccc;
    border-left: 0px;
}

.blog-con .text {
    border-left: 1px solid #ccc;
    padding-left: 54px;
    height: 100%;

}

.blog-con .text ul {
    color: var(--nutral-color, #CCC);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.blog-con .text ul li {
    margin-bottom: 12px;
}

.blog-con.ar .card-blog {
    padding-left: 126px;
    padding-right: 0px;
}

.card-blog {
    padding-right: 126px;
    margin-bottom: 126px;
}

.card-blog img {
    width: 100%;
    height: 412px;
    margin-bottom: 8px;
    cursor: pointer;
    object-fit: cover;
}

.card-blog .date {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    text-align: right;
    margin-bottom: 26px;
}

.card-blog h4 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 54px;
}

.card-blog p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 54px;

}

.p-description {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

}

.active {
    color: #444484;

}

.flex-aswad-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex-aswad-between h5 {
    color: var(--nutral-color, #CCC);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 17px;
}

.facebook,
.linked-in {
    width: 39px;
    padding: 5px;
    height: 39px;
    color: #444484;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    margin-right: 17px;
}

.blog-con.ar h3 {
    margin-right: 0px;
    margin-left: 16px;
}

.card-blog h3 {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 16px;
}

.blog-con.ar .card-blog h3+svg {
    transform: rotateY(180deg);
}

.card-blog h3+svg {
    width: 40px;
    height: 40px;
}

.col-scroll {
    margin-bottom: 126px;
}


.col-scroll::-webkit-scrollbar {
    width: 0px;
    border-radius: 10px;
}

/* Track */
.col-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.col-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
.col-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media all and (min-width:1901px) {
    .blog-con {
        padding-left: 30.9%;
        padding-right: 30.1%;

        &.ar {
            padding-left: 30.1%;
            padding-right: 30.9%;
        }
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .blog-con {
        padding-left: 24.6%;
        padding-right: 19.6%;

        &.ar {
            padding-left: 21.6%;
            padding-right: 24.6%;
        }
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .blog-con {
        padding-top: 90px;
    }

    .blog-con.ar .text {
        padding-left: 0px;
        padding-right: 13px;
    }

    .blog-con .text {
        padding-left: 13px;
    }

    .blog-con .text h4 {
        font-size: 26px;
    }

    .blog-con .text ul {
        font-size: 16px;
    }

    .blog-con.ar .card-blog {
        padding-right: 0px;
        padding-left: 50px;
    }

    .card-blog {
        padding-right: 50px;
        margin-bottom: 90px;
    }
}

@media all and (max-width:767px) {
    .card-blog h3+svg {
        width: 20px;
        height: 20px;
    }

    .o-1 {
        order: 2;
    }

    .o-2 {
        order: 1;
        margin-top: 40px;
    }

    .blog-con {
        padding-top: 0px;
        padding-left: 40px !important;
        padding-right: 40px !important;

    }

    .col-scroll {
        margin-bottom: 40px;
    }

    .blog-con.ar .text {
        padding-right: 0px;
        border-right: 0px;
    }

    .blog-con .text {
        border-left: none;
        padding-left: 0px;
        width: 100%;
        margin-bottom: 40px;
    }

    .blog-con .text h4 {
        font-size: 18px;
        margin-bottom: 16px;
    }

    .blog-con .text ul {
        display: flex;
        flex-wrap: wrap;
    }

    .blog-con.ar .text ul li {
        margin-right: 0px;
        margin-left: 8px;
    }

    .blog-con .text ul li {

        font-size: 12px;
        margin-right: 8px;
    }

    .blog-con.ar .card-blog {
        padding-left: 0px;
    }

    .card-blog {
        padding-right: 0px;
        padding-bottom: 40px;
        margin-bottom: 0px;
        border-bottom: 1px solid #ccc;
    }

    .col-scroll::-webkit-scrollbar {
        width: 5px;
    }

    .card-blog img {
        height: 279px;
    }

    .card-blog .date {
        font-size: 9px;
        margin-bottom: 16px;
    }

    .card-blog h4 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .card-blog p {
        font-size: 10px;
    }

    .blog-con.ar .flex-aswad-between h5,
    .blog-con.ar .facebook,
    .blog-con.ar .linked-in {
        margin-right: 0px;
        margin-left: 9px;
    }

    .flex-aswad-between h5 {
        font-size: 15.079px;
        margin-bottom: 0px;
        margin-right: 9px;
    }

    .facebook,
    .linked-in {
        width: 20px;
        height: 20px;
        margin-right: 9px;
    }

    .card-blog h3 {
        font-size: 12px;
    }

}