.next-project {
    border-top: 1px solid rgb(193, 192, 192);
}

.next-project img {
    margin-right: 54px;
    width: 171px;
    height: 171px;
}

.next-project .text {
    display: flex;
    flex-direction: column;
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    margin-right: 50px;
}

.next-project .text h4 {
    font-size: 30.141px;
    font-weight: 400;
    margin-bottom: 32px;
}

.next-project .text p {
    font-size: 20.106px;
    font-weight: 300;
}

.next-project .b {
    border-left: 1px solid rgb(193, 192, 192);
    padding-top: 54px;
    padding-bottom: 158px;
}

.next-project .b .flex-aswad-center {
    margin-top: 50px;
}

@media all and (max-width: 767px) {


    .next-project .b .flex-aswad-center {
        margin-top: 0px;
    }

    .next-project img {
        margin-right: 9.5px;
    }

    .next-project .b {
        padding: 16px 18px;
    }

    .next-project img {
        width: 54px;
        height: 54px;
    }

    .next-project svg {
        width: 23.551px;
        height: 16.775px;
    }

    .next-project .text {
        margin-top: 54px;
        margin-right: 10px;
    }

    .next-project .text-next {
        margin-top: 0px;
    }

    .next-project .text h4 {
        font-size: 7.975px;
        margin-bottom: 5.7px;
    }

    .next-project .text p {
        font-size: 5.982px;
        margin-bottom: 0px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .next-project img {
        margin-right: 31px;
    }

    .next-project .text {
        margin-right: 20px;
    }

    .next-project .text h4 {
        font-size: 25.255px;
        margin-bottom: 18px;
    }

    .next-project svg {
        width: 30px;
        height: 30px;
    }

    .next-project .text p {
        font-size: 18.942px;
    }

    .next-project img {
        width: 121px;
        height: 121px;
    }

    .next-project .b .flex-aswad-center {
        margin-top: 0px;
    }

    .next-project .b {
        padding-top: 54px;
        padding-bottom: 58px;
        padding-left: 20px;
    }

}