.main-international-recogition {
    padding-top: 126px;
    padding-bottom: 126px;
    background: var(--primery-color, #444484);
}

.main-international-recogition .container-fluid {
    padding-left: 6.6%;
    padding-right: 6.6%;
}

.main-international-recogition.ar .Awards-text {
    padding-left: 0px;
    padding-right: 15px;
}

.Awards-text {
    padding-left: 15px;
}

.main-international-recogition.ar .Awards-text h2,
.main-international-recogition.ar .Awards-text p,
.main-international-recogition.ar .Awards-text h6,
.main-international-recogition.ar .second-text p {
    font-family: "Rubik";
}

.Awards-text h2 {
    color: #FFF;
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    /* 47.727% */
    margin-bottom: 54px;
}

.Awards-text p {
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    /* 116.667% */
    margin-bottom: 126px;
}

.flex-aswad-start {
    display: flex;
    flex-direction: row;
}

.main-international-recogition.ar .Awards-text h6 {
    margin-right: 0px;
    margin-left: 126px;
}

.Awards-text h6 {
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    /* 70% */
    margin-right: 126px;
}

.Awards-text svg {
    width: 35px;
    height: 22.5px;
}

.main-international-recogition.ar .second-text {
    padding-left: 0px;
    padding-right: 126px;
}

.second-text {
    padding-left: 126px;
}

.second-text p {
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    /* 116.667% */
}

.Awards-text svg {
    cursor: pointer;
}

.international-recogition-slider {
    transition: .3s;
    padding-top: 106px;
    padding-bottom: 106px;
    border-bottom: 2px solid #ccc;
}

.international-recogition-card {
    width: 350px;
    height: 400px;
    border: 1px solid #ccc;
    padding: 40px;
}

.international-recogition-slider .swiper-backface-hidden .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.international-recogition-slider .swiper-button-prev:after,
.international-recogition-slider .swiper-rtl .swiper-button-next:after,
.international-recogition-slider .swiper-button-next:after,
.international-recogition-slider .swiper-rtl .swiper-button-prev:after {
    color: #ccc !important;
}

.international-recogition-slider {
    padding-left: 6.6%;
    padding-right: 6.6%;
}

@media all and (min-width:1901px) {
    .main-international-recogition.ar .container-fluid {
        padding-right: 30.4%;
        padding-left: 30%;
    }

    .main-international-recogition .container-fluid,
    .international-recogition-slider {
        padding-left: 30.4%;
        padding-right: 30%;
    }

}

@media all and (min-width:1540px) and (max-width:1900px) {
    .main-international-recogition.ar .container-fluid {
        padding-right: 23%;
        padding-left: 24%;
    }

    .main-international-recogition .container-fluid,
    .international-recogition-slider {
        padding-left: 25%;
        padding-right: 24%;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .main-international-recogition {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .international-recogition-card {
        width: 250px;
        height: 300px;
        border: 1px solid #ccc;
        padding: 20px;
    }

    .Awards-text h2 {
        font-size: 27.362px;
        margin-bottom: 33px;
    }

    .Awards-text p {
        font-size: 11.194px;
    }

    .main-international-recogition.ar .second-text {
        padding-left: 0px;
        padding-right: 61px;
    }

    .second-text {
        padding-left: 61px;
    }

    .second-text p {
        font-size: 10px;
    }


}

@media all and (max-width:767px) {
    .main-international-recogition {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .Awards-text h2 {
        font-size: 24px;
        margin-bottom: 32px;
    }

    .Awards-text p {
        font-size: 10px;
        margin-bottom: 32px;
    }

    .second-text p {
        font-size: 10px;
    }

    .main-international-recogition.ar .second-text {
        padding-left: 0px;
        padding-right: 16px;
    }

    .second-text {
        padding-left: 16px;
        margin-bottom: 32px;
    }

    .flex-aswad-start {
        align-items: center;
    }

    .main-international-recogition.ar .Awards-text h6 {
        margin-left: 99px;
        margin-right: 0px;
    }

    .Awards-text h6 {
        margin-right: 99px;
    }

    .o-awards-2 {
        order: 2;
    }

    .o-awards-1 {
        order: 1;
    }
}