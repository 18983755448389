.space-planning-col {
    border-left: 1px solid var(--nutral-color, #CCC);
    padding: 69px;
    margin-bottom: 69px;
}

.space-planning-con.ar .space-planning-col {
    border-left: 0px;
    border-right: 1px solid var(--nutral-color, #CCC);
}

.m-last {
    margin-bottom: 126px;
}

.space-planning-con.ar .space-planning-col h2,
.space-planning-con.ar .space-planning-col p {
    font-family: "Rubik";
}

.space-planning-col h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 46px;
    /* 52px */
}

.space-planning-col p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    width: 70%;
    /* 23.4px */
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .space-planning-col {
        padding: 30px;
        /* margin-bottom: 20px; */


    }

    .space-planning-con {
        padding-left: 47px;
        margin-left: 0px;

        &.ar {
            margin-right: 0px;
        }
    }

}

@media all and (max-width:767px) {
    .space-planning-con {
        padding: 0px 40px !important;

        &.ar {
            margin-right: 0px;
        }
    }

    .space-planning-con {
        margin-left: 0px;
    }

    .space-planning-col {
        padding: 20px;
        margin-bottom: 20px;
    }

    .space-planning-col h2 {
        font-size: 12.75px;
        margin-bottom: 14px;
    }

    .space-planning-col p {
        font-size: 5.737px;
        line-height: 13px;
        width: 100%;
    }
}