.footer {
  height: 347px;
  background-image: url("../../Assets/Images/bg-footer.svg"),
    linear-gradient(to right, white 41.7%, #e9e9e9 41.7%, #e9e9e9 80%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  color: #fff;
  font-family: Roboto;

  &.ar {
    background-image: url("../../Assets/Images/footer-ar.svg"),
      linear-gradient(to left, white 41.7%, #e9e9e9 41.7%, #e9e9e9 80%) !important;
    font-family: "Rubik";
    background-size: cover;
    background-position:center left;
    & .location-icon-a {
      font-family: "Rubik";
    }

    & .Subscribe {
      font-family: "Rubik";
    }

    & .Subscribe-input input {
      font-family: "Rubik";
      border-right: 1px solid white;
      border-left: 0px;
      padding-left: 0px;
      padding-right: 10px;
      color: white;
    }

    .Subscribe-input input:focus {
      color: white;
      border-right: 1px solid white !important;
      border-left: 0px !important;
    }

    & .Subscribe-input+span {
      font-family: "Rubik";
    }

    & .Subscribe-input input::placeholder {
      font-family: "Rubik";
    }

    & .Subscribe-input a {
      font-family: "Rubik";
    }

    & ul {
      font-size: 18.5px;
    }
  }
}

.location-icon-a {
  color: #fff;
  font-family: Roboto;
  font-size: 20.314px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 34px;
}

.location-icon {
  width: 30px;
}

@media (min-width: 600px) {
  .location-icon {
    width: 40px;
  }
}

@media (min-width: 900px) {
  .location-icon {
    width: 60px;
  }
}

@media (min-width:1000px) {
  .location-icon {
    width: 90px;
  }
}

@media (min-width:1500px) {
  .location-icon {
    width: 70px;
  }
}

@media (min-width:1800px) {
  .location-icon {
    width: 130px;
  }
}




.Subscribe {
  font-family: Roboto;
  color: #fff;
  font-size: 20.314px;
  font-weight: 300;
  margin-bottom: 20px;
}

.Subscribe-input {
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 1.5fr 1fr;
}

.Subscribe-input input {
  color: white;
  font-family: Roboto;
  background-color: transparent;
  border: 1px solid white;
  padding: 5px;
  border-right: 0px;
  padding-left: 10px;
  width: 100%;
  height: 100%;
}

.Subscribe-input input:focus {
  color: white;
  border: 1px solid white !important;
  border-right: 0px !important;
  outline: 0 !important;
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.Subscribe-input+span {
  font-family: Roboto;
  font-weight: 300;
}

.Subscribe-input input::placeholder {
  font-family: Roboto;
  color: rgba(255, 255, 255, 0.493);
}

.Subscribe-input a {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid white;
  font-family: Roboto;
  color: rgba(255, 255, 255, 0.493) !important;
}

.Subscribe-input a:hover {
  background-color: white;
  transition: 0.3s;
  color: #474384 !important;
}

.Subscribe-input button {
  font-size: 0.6rem;
}

.footer .col-md-3.flex-aswad-center {
  padding-left: 50px;
  padding-right: 50px;
}

.footer.none {
  background-image: url("../../Assets/Images/bg-footer.svg") !important;

  &.ar {
    background-size: 100% 100%;
    background-image: url("../../Assets/Images/bg-footerAR.png") !important;
    font-family: "Rubik";
  }
}

.footer ul li a {
  color: #e9e9e9;
}

.footer ul li a:hover {
  color: #e9e9e998;
  transition: 0.3s;
}

.footer.all {
  background-image: url("../../Assets/Images/bg-footer.svg"),
    linear-gradient(to right, #e9e9e9 0%, #e9e9e9 80%) !important;

  &.ar {
    background-size: 100% 100%;
    background-image: url("../../Assets/Images/bg-footerAR.png"),
      linear-gradient(to right, #e9e9e9 0%, #e9e9e9 80%) !important;
    font-family: "Rubik";
  }
}

.footer .row {
  height: 100%;
}

.footer .img {
  border: 2px solid white;
  padding: 10px;
}

.footer ul {
  font-size: 20.314px;
  font-weight: 300;
}

.footer ul li:not(:last-child) {
  margin-bottom: 7px;
}

.follow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
}

.footer .follow-us {
  font-size: 20.314px;
  font-weight: 300;
  margin-bottom: 20px;
}

.copy {
  font-size: 16px;
  font-weight: 300 !important;
}

.logo-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.footer .img iframe {
  width: 100%;
  height: 100%;
}

.icon-location {
  color: white;
}

.row-footer {
  padding-top: 50px;
}

.icon-con {
  width: 43.45px;
  height: 43.45px;
  padding: 8.5px;
  border: 0.810459px solid #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer.ar .icon-con:not(:last-of-type) {
  margin-left: 22px;
  margin-right: 0px;
}

.icon-con:not(:last-of-type) {
  margin-right: 22px;
}

@media all and (min-width: 1901px) {
  .location-fo-col {
    align-items: flex-end;
    height: 81.5%;
  }

  /* .location-icon {
    width: 35%;
  } */

  .location-icon-a {
    margin-bottom: 40px;
  }

  .footer {
    height: 448px;
  }

  .footer.ar .logo-footer svg {
    width: 193px;
    height: 193px;
  }

  .logo-footer svg {
    width: 170px;
    height: 170px;
  }

  .col-md-4.fff {
    height: 75% !important;
    padding-top: 0px !important;
    justify-content: center !important;
  }
}

@media all and (min-width: 1540px) and (max-width: 1900px) {
  .location-fo-col {
    align-items: flex-end;
    height: 81.5%;
  }

  .location-icon {
    width: 40%;
  }

  .footer {
    height: 417px;
  }

  .logo-footer svg {
    width: 150px;
    height: 150px;
  }

  .col-md-4.fff {
    height: 75% !important;
    padding-top: 0px !important;
    justify-content: center !important;
  }
}

@media all and (min-width: 600px) {
  .Subscribe-input input {
    height: 45px;
  }
}

/* @media all and (max-width: 450px) {
  .logo-footer svg {
    display: none;
  }
} */

@media all and (max-width: 767px) {
  .icon-con {
    width: 15px;
    height: 15px;
    padding: 0px;
  }

  .icon-con svg {
    width: 5.9px;
    height: 5.9px;
  }

  .footer.ar .icon-con:not(:last-of-type) {
    margin-left: 5.2px;
    margin-right: 0px;
  }

  .icon-con:not(:last-of-type) {
    margin-right: 5.2px;
  }

  .row-footer {
    padding-top: 0px;
  }

  .footer-small {
    padding-top: 0% !important;
    justify-content: center !important;
    height: 69.5px;
  }

  .Subscribe {
    margin-bottom: 5px;
  }

  .footer .col-3 {
    justify-content: flex-start !important;
    margin-top: 12px !important;
  }

  .location-icon-a {
    font-size: 5px;
    margin-bottom: 18px;
  }

  .footer.ar .location-icon-a {
    margin-bottom: 17px;
  }

  /* .location-icon {
    width: 100%;
    height: 100%;
  } */

  .footer {
    background-image: url("../../Assets/Images/bg-footer.svg"),
      linear-gradient(to right, #e9e9e9 41.7%, #e9e9e9 41.7%, #e9e9e9 80%);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    padding-top: 30px;
  }

  .footer .col-md-3.flex-aswad-center {
    padding: 0;
    padding-bottom: 20px;
  }

  .footer .row {
    height: 100%;
  }

  .footer .col-1,
  .footer .col-3,
  .footer .col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer.all.ar .logo-footer svg {
    width: 47.747px;
    height: 47.706px;
  }

  .Subscribe-input input {
    font-size: 5.113px;
    height: 20px;
    padding: 5px;
    border-radius: 0px !important;
    border: 1px solid white !important;
  }

  .Subscribe-input a {
    padding: 5px;
    font-size: 5.113px;
    height: 20px;
  }

  .footer .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 50px;
    padding: 5px;
  }

  .footer .img img {
    width: 57.142px;
    height: 33.949px;
  }

  .footer ul,
  .Subscribe {
    font-size: 5px;
  }

  .footer.ar ul {
    font-size: 4px;
  }

  .footer.ar .Subscribe {
    margin-top: 10px;
  }

  .footer ul li:not(:last-child) {
    margin-bottom: 4px;
  }

  .footer .follow-us {
    font-size: 6.113px;
    margin-bottom: 0px;
  }

  .follow-us+.flex-aswad-center {
    margin-bottom: 5px !important;
  }

  .footer .follow .copy {
    font-size: 6.051px;
  }

  .follow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: flex-start !important;
  }

  .logo-footer svg {
    width: 40.747px;
    height: 40.706px;
  }

  .logo-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }

  .icon-location {
    width: 50px !important;
    height: 50px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .icon-con {
    width: 27.08px;
    height: 27.08px;
    padding: 6px;
  }

  .icon-con svg {
    width: 14.89px;
    height: 14.89px;
  }

  .footer.ar .icon-con:not(:last-of-type) {
    margin-left: 14.26px;
    margin-right: 0px;
  }

  .icon-con:not(:last-of-type) {
    margin-right: 14.26px;
  }

  .icon-location {
    width: 70px !important;
    height: 70px !important;
  }

  .footer {
    height: 100%;
    padding-top: 15px;
  }

  .footer.ar {
    padding-top: 15px;
  }

  .footer .col-md-3.flex-aswad-center {
    padding: 0;
    padding-bottom: 20px;
  }

  .footer-small {
    justify-content: flex-start !important;
    padding-top: 0px;
  }

  .footer.ar .footer-small {
    padding-top: 11px !important;
  }

  .footer .col-1,
  .footer .col-3,
  .footer .col-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Subscribe-input input {
    font-size: 12.113px;
    width: 102px;
    height: 31px;
    padding: 7px;
  }

  .Subscribe-input a {
    padding: 5px;
    font-size: 12.113px;
    /* width: 102px; */
    height: 31px;
  }

  .logo-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }

  .footer .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 60%;
  }

  .footer .img img {
    width: 108.931px;
    height: 64.424px;
  }

  .footer ul,
  .Subscribe {
    font-size: 12.002px;
  }

  .footer.ar ul {
    font-size: 10px !important;
  }

  .footer.ar .location-icon-a {
    font-size: 12px;
  }

  .footer ul li:not(:last-child) {
    margin-bottom: 11px;
  }

  .footer .follow-us {
    font-size: 17.002px;
    margin-bottom: 0px;
  }

  .follow-us+.flex-aswad-center {
    margin-bottom: 11px !important;
  }

  .footer .follow .copy {
    font-size: 12.939px;
  }

  .follow {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .logo-footer svg {
    width: 80px;
    height: 80px;
  }
}

._wrap {
  justify-content: flex-start;
  row-gap: 5px;
  flex-wrap: wrap;
}