.cx-slider {
    position: relative;
    padding-bottom: 126px;
}

.cx-slider .next {
    right: -33px;
    top: 40%;
}

.cx-slider .prev {
    left: -33px;
    top: 39.5%;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .cx-slider {
        padding: 37px !important;
        padding-top: 0px !important;
        padding-bottom: 54px !important;
    }

    .cx-slider .next {
        right: 0px;
    }

    .cx-slider .next svg,
    .cx-slider .prev svg {
        width: 40px;
        height: 40px;
    }

    .cx-slider .prev {
        left: 0px;
    }
}

@media all and (max-width:767px) {
    .cx-slider {
        padding: 40px !important;
        padding-top: 0px !important;
    }

    .cx-slider .next {
        right: 0px;
    }

    .cx-slider .next svg,
    .cx-slider .prev svg {
        width: 20px;
        height: 20px;
    }

    .cx-slider .prev {
        left: 0px;
    }

}