.brands-con {
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 6.6%;

    &.ar {
        padding-right: 6.6%;
    }
}

.brands-con.ar h2,
.brands-con.ar p {
    font-family: "Rubik";
}

.brands-con h2 {
    color: var(--primery-color, #444484);
    font-family: Roboto Slab;
    font-size: 30px;
    font-weight: 400;
    margin-left: 2px;
    margin-bottom: 54px;
}

.categories .col:nth-child(2) {
    padding-left: 0px;
}

.img-brands-imges {
    width: 93% !important;
    height: 238px !important;
}

.brands-con p {
    color: var(--primery-color, #444484);
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 54px;
    width: 60%;
}

.select-flex {
    display: flex;
}

.select-flex select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image:
        linear-gradient(45deg, transparent 50%, #444484 50%),
        linear-gradient(135deg, #444484 50%, transparent 50%),
        linear-gradient(to right, transparent, transparent);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
    background-repeat: no-repeat;
    margin-right: 90px;
    font-size: 18px;
    color: #444484;
    font-family: Roboto;
    border-color: #CCC;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 24.7%;
}

.select-flex.ar select {
    margin-right: 0px;
    margin-left: 90px;
    background-position:
        calc(11% - 20px) calc(1em + 2px),
        calc(11% - 15px) calc(1em + 2px),
        100% 0;
}

.select-flex.ar select:nth-child(2) {
    margin-right: 0px;
    margin-left: 115px;
}

.select-flex select:nth-child(2) {
    margin-right: 115px;

}

.search-btn {
    background-color: #444484;
    color: white;
    font-family: Roboto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 10px 35px 10px;
    cursor: pointer;
    width: 50%;
}

.search-btn:hover {
    background-color: #31325f;
    transition: .3s;
}

@media all and (min-width:1901px) {
    .img-brands-imges {
        width: 94% !important;
    }

    .brands-con {
        padding-left: 31.1% !important;
        padding-bottom: 3.1%;

        &.ar {
            padding-right: 29.9%;
        }

        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

    .text-con-brands {
        width: 57%;
    }

    .brands-logo-con {
        margin-right: 26.6%;
    }

    .brands-con p {
        width: 100%;
    }

    .brands-logo-con img {
        width: 18% !important;
    }

    .select-flex select {
        width: 25.8%;
    }

    .select-flex.ar select {
        margin-right: 0px;
        margin-left: 90px;
        background-position: calc(26% - 20px) calc(1em + 2px), calc(25.5% - 15px) calc(1em + 2px), 100% 0;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .img-brands-imges {
        width: 94% !important;
    }

    .brands-con {
        padding-left: 24.54% !important;
        padding-bottom: 3.1%;

        &.ar {
            padding-right: 23.54%;
        }

        /* display: flex;
        justify-content: center;
        align-items: center; */
    }

    .text-con-brands {
        width: 69%;
    }

    .brands-logo-con {
        margin-right: 26.6%;
    }

    .brands-con p {
        width: 100%;
    }

    .brands-logo-con img {
        width: 18% !important;
    }

    .select-flex select {
        width: 25.8%;
    }

    .select-flex.ar select {
        margin-right: 0px;
        margin-left: 90px;
        background-position: calc(26% - 20px) calc(1em + 2px), calc(25.5% - 15px) calc(1em + 2px), 100% 0;
    }


}

@media all and (width:1024px) {
    .select-flex.ar select {
        background-position:
            calc(14.2% - 20px) calc(1em + 2px),
            calc(14% - 15px) calc(1em + 2px),
            100% 0;
    }

    .brands-con {
        /* padding-top: 94px; */
        padding-bottom: 94px;
        padding-left: 55px;

        &.ar {
            padding-right: 55px;
        }

    }

    .brands-con h2 {
        font-size: 26px;
    }

    .brands-con p {
        width: 100%;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .select-flex.ar select {
        background-position:
            calc(24.1% - 20px) calc(1em + 2px),
            calc(24% - 15px) calc(1em + 2px),
            100% 0;
    }

    .brands-con {
        padding-bottom: 94px;
        padding-left: 55px;

        &.ar {
            padding-right: 55px;
        }
    }

    .brands-con h2 {
        font-size: 26px;
    }

    .brands-con p {
        width: 100%;
    }
}

@media all and (max-width:767px) {
    .row-sm-categories .col {
        padding-left: 0px;
    }

    .brands-con {
        padding: 40px;

        &.ar {
            padding-right: 0px;
            padding: 40px;
        }
    }

    .brands-con h2 {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .brands-con p {
        font-size: 10px;
        width: 100%;
        margin-bottom: 16px;
    }

    .select-flex {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }


    .search-btn {
        font-size: 13px;
        padding: 10px 25px 10px;
    }
}