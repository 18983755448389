.nav-jab {
    padding: 40px 6.6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 20%;
    height: 100vh;
    z-index: 111111;
    background-color: #474384;
    padding-top: 68px;
    padding-left: 60px;
    padding-bottom: 100px;
    transition: .5s;
    overflow-y: auto;
    &.ar {
        right: unset;
        left: 0;
        padding-left: unset;
        padding-right: 60px;
    }
}

.menu::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
}

.main-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

}

.close {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.burger-con {
    cursor: pointer;
    direction: rtl;
}

.menu .close {
    margin-bottom: 29px;
    cursor: pointer;
}

.main-menu ul {
    font-family: Roboto Slab;
    color: #FFF;
    font-size: 22px;
    font-weight: 300;
}

.main-menu.ar ul {
    font-family: "Rubik";
}

.main-menu ul li a {
    color: white;
}

.arabic-logo {
    width: 100%;
    height: 100px;
    cursor: pointer;
}

.arabic-logo._en{
    height: auto;
    max-height:60px ;
}

.main-menu ul li a:hover {
    color: rgb(215, 215, 215);
    transition: .3s;
}


.main-menu ul:first-child {
    margin-bottom: 50px;
}

.main-menu ul:last-child {
    font-size: 17.778px;
}

.main-menu ul li:not(:last-child) {
    margin-bottom: 19px;
}

.line-one {
    width: 60px;
    background-color: #444484;
    height: 2px;
}

.burger-con:hover .line-one:nth-child(1) {
    animation-duration: .3s;
}

.burger-con:hover .line-one:nth-child(2) {
    animation-delay: .2s;
}

.burger-con:hover .line-one:nth-child(3) {
    animation-delay: .4s;
}

.burger-con:hover .line-one {
    animation: headerAnimation .5s;
    animation-timing-function: ease-out;

}

.line-one:not(:last-child) {
    margin-bottom: 10px;
}

.qodef-mobile-header--side-area #qodef-page-mobile-header-inner .qodef-side-area-mobile-header-opener {
    margin-left: 20px;
}

.qodef-opener-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #444484;
}

.qodef-opener-icon .qodef-m-icon {
    position: relative;
    visibility: visible;
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu {
    display: block;
}

.qodef-svg--menu {
    fill: none;
    stroke: currentColor;
    stroke-miterlimit: 10;
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line {
    stroke-dasharray: 45;
    stroke-dashoffset: 90;
    transition: stroke-dashoffset .45s ease-in-out;
}

.qodef-opener-icon.qodef-source--predefined .qodef-search-close {
    transform: rotate(0);
    transform-origin: center;
    transition: transform .3s ease-in-out
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line {
    stroke-dasharray: 45;
    stroke-dashoffset: 90;
    transition: stroke-dashoffset .45s ease-in-out
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line:nth-of-type(2) {
    transition-delay: .15s
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu line:nth-of-type(3) {
    transition-delay: .3s
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--close-menu line {
    stroke-dasharray: 22;
    stroke-dashoffset: 44;
    transition: stroke-dashoffset .44s ease-in-out
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--close-menu line:nth-of-type(2) {
    transition-delay: .22s
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--close-menu line,
.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--menu line {
    stroke-dashoffset: 0
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-search-close {
    transform: rotate(90deg)
}


@media all and (max-width: 767px) {
    .menu .close {
        padding-top: 21px;
    }

    .arabic-logo {
        width: 119px;
        height: 50.282px;
    }

    .arabic-logo ._en{
        height: 40.282px;
    }

    .nav-jab {
        padding: 40px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-jab .logo {
        width: 48.282px;
        height: 48.282px;
    }

    .burger-con svg {
        width: 30.282px;
        height: 30.282px;
    }

    .menu .close svg {
        width: 30.282px;
        height: 30.282px;
    }

    .menu .close {
        margin-bottom: 22px;
        margin-left: 20px;
    }

    .main-menu ul {
        font-size: 15.523px;
    }

    .main-menu ul:last-child {
        font-size: 13.532px;
    }

    .main-menu ul:first-child {
        margin-bottom: 43px;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .nav-jab {
        padding: 40px 46px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-jab.sample {
        padding: 40px 53px;
    }

    .menu.ar .close {
        margin-right: 40px;
    }

    .close {
        padding-top: 50px;
        margin-left: 75px;
    }

}

@media all and (min-width:1901px) {
    .arabic-logo {
        padding-left: 6px;
    }

    .nav-jab {
        padding: 40px 30.6%;
    }

    .nav-jab.home {
        padding: 40px 6.6%;
    }

    .main-menu ul {
        font-size: 25px;
    }

    .main-menu ul:first-child {
        margin-bottom: 59px;
    }

    .main-menu ul:last-child {
        font-size: 20.778px;
    }
}

@media all and (min-width:1540px) and (max-width:1900px) {
    .arabic-logo {
        padding-left: 6px;
    }

    .nav-jab {
        padding: 40px 24.6%;
    }

    .nav-jab.home {
        padding: 40px 6.6%;
    }

    .main-menu ul {
        font-size: 25px;
    }

    .main-menu ul:first-child {
        margin-bottom: 59px;
    }

    .main-menu ul:last-child {
        font-size: 20.778px;
    }
}