.featured-project {
    font-family: Roboto Slab;
    color: var(--primery-color, #444484);
    height: 70vh;

    &.ar {
        font-family: "Rubik";
    }
}

.featured-project .row {
    height: 100%;
}

.featured-project .row .col-md-7 {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.featured-project.ar .text-arrow {
    direction: ltr;
}

.featured-project .text-arrow {
    display: flex;
    align-items: center;
}

.featured-project .text {
    margin-right: 88px;
}

.featured-project .text-arrow h2 {
    font-size: 36px;
    font-weight: 400;
}

.featured-project .text-arrow p {
    font-size: 36px;
    font-weight: 300;
}

.w {
    height: 512px;
}

@media all and (max-width: 767px) {

    .featured-project .row .col-md-7 {
        height: 200px;
    }

    .featured-project {
        padding: 0px 45px;
        margin-bottom: 32px;
        height: 100%;
    }

    .featured-project.ar .col-md-5 {
        justify-content: flex-end;
    }

    .featured-project .col-md-5 {
        order: 2;
    }

    .w {
        height: 200px;
        margin-bottom: 16px;
        order: 1;
    }

    .w img {
        height: 200.886px;
        margin-bottom: 16px;
    }

    .featured-project .text-arrow h2 {
        font-size: 20.843px;
    }

    .featured-project .text-arrow p {
        font-size: 15.633px;
    }

    .featured-project .text {
        margin-right: 38px;
    }

    .featured-project .text-arrow svg {
        width: 30px;
        height: 35px;
        margin-bottom: 20px;
    }
}

@media all and (min-width:2500px) {
    .featured-project .row .col-md-7 {
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .featured-project {
        height: 45vh;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .featured-project {
        height: 30vh;
    }

    .featured-project .w {
        height: 350px;
        order: 2;
    }

    .featured-project .w img {
        height: 356px;
        margin-bottom: 0px;
    }

    .featured-project .case-text {
        padding-left: 35px;
    }

    .featured-project .text-arrow h2,
    .featured-project .text-arrow p {
        font-size: 22px;
    }

    .featured-project .text {
        margin-right: 10px;
    }

    .featured-project .text-arrow svg {
        width: 40px;
        height: 40px;
    }

}